import Vue from 'vue'
import App from './App.vue'

import store from '@/Store'
import vuetify from "@/Plugins/vuetify"
import router from '@/Router'
import i18n from "@/Assets/I18n"

import VueCookies from 'vue-cookies';
import ColorInitializer from "@/Plugins/colorInitializer"

import "@/Assets/Styles/planete-online.scss"
import "@/Mixins"
import "@/Plugins"
import "@/Components"

Vue.use(VueCookies);
Vue.use(ColorInitializer);

Vue.config.productionTip = false


new Vue({
  store,
  router,
  vuetify,
  i18n: i18n(),
  render: h => h(App)
}).$mount('#app')

