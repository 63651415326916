// plugins/colorInitializer.js
import store from '@/Store';

const ColorInitializer = {
  async install(Vue) {
    // Créer une instance Vue dédiée pour la gestion des couleurs
    const colorManager = new Vue({
      created() {
        this.initializeColors();
      },
      methods: {
        async initializeColors() {
          try {
            await store.dispatch('initializeColors');
          } catch (error) {
            console.error('Erreur d\'initialisation des couleurs:', error);
          }
        }
      }
    });

    // Ajouter une méthode globale pour forcer le rechargement si nécessaire
    Vue.prototype.$reloadColors = () => colorManager.initializeColors();
  }
};

export default ColorInitializer;

// Exporter également une fonction pour l'initialisation manuelle si nécessaire
export const initColors = () => store.dispatch('initializeColors');