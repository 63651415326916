import Api from "@/Services/Api";

export default {
    async getMenuInterface() {
        let response = await Api().get("/support-vision/droit/menu-interface")
        return response.data
    },
    async postMenuInterface(data) {
        let response = await Api().post("/support-vision/droit/menu-interface", {menusInterface: data})
        return response.data
    },
    async getDroit() {
        let response = await Api().get("/support-vision/droit")
        return response.data
    },
    async getDroitByTag(tag) {
        let response = await Api().get(`/support-vision/droit/${tag}`)
        return response.data
    },
    async postDroit(data) {
        let response = await Api().post("/support-vision/droit", {droit: data})
        return response.data
    },
    async putDroit(data) {
        let response = await Api().put("/support-vision/droit", {droit: data})
        return response
    },
    async removeDroit(tag) {
        let response = await Api().delete(`/support-vision/droit/${tag}`)
        return response
    },
    async saveTeamAndRole(data) {
        let response = await Api().post("/support-vision/droit/save-team-role", {teamRole: data})
        return response.data
    },
    async getTeamAndRole() {
        let response = await Api().get("/support-vision/droit/team-roles")
        return response
    },
    async getTeamAndRoleByTeam(team) {
        let response = await Api().get(`/support-vision/droit/team-role/${team}`)
        return response.data
    },
    async getTeamAndRoleById(userId) {
        try {
          const response = await Api().get(`/support-vision/droit/id-role/${userId}`)
          return response.data
        } catch (error) {
          console.error('Erreur lors de la récupération des droits d\'équipe:', error)
          throw error
        }
      },
    async editTeamAndRole(id, data) {
        let response = await Api().put(`/support-vision/droit/team-role/${id}`, data)
        return response
    },
    async removeTeamAndRole(userId) {
        let response = await Api().delete(`/support-vision/droit/team-role/${userId}`)
        return response
    },

}